import React from 'react';
import { StyledButton } from '../components/StyledButton';

import { StaticImage } from 'gatsby-plugin-image';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Seo from '../components/Seo';
import { FaDrum } from 'react-icons/fa';
import '../style/unterricht.scss';

const Unterricht = () => {
  return (
    <>
      <Seo
        title="Schlagzeugunterricht in Mönchengladbach"
        description="Lerne Schlagzeug in Mönchengladbach bei Carsten Grüter in der RedBox oder online. Schlagzeugunterricht für alle Altersklassen und Level. Probiere es aus bei einer kostenlosen Probestunde"
        keywords="Schlagzeugunterricht Mönchengladbach, Schlagzeug lernen Mönchengladbach"
      />
      <div className="card flex unterricht">
        <div className="flex">
          <div className="card-left">
            <div className="sideNav ">
              <AnchorLink to="/unterricht#ankertop">
                <h2>
                  <FaDrum className="icon" /> Unterricht
                </h2>
              </AnchorLink>
              <div className="mobile sidenav">
                <AnchorLink
                  className="btn-sideNav"
                  to="/unterricht#ankeronline"
                >
                  Online
                </AnchorLink>
                <AnchorLink className="btn-sideNav" to="/unterricht#ankercloud">
                  Cloud
                </AnchorLink>

                <AnchorLink
                  className="btn-sideNav"
                  to="/unterricht#ankerpreise"
                >
                  Preise
                </AnchorLink>
              </div>
            </div>
          </div>

          <div className="card-right">
            <div className="center unterricht">
              <h1 id="ankertop">Schlagzeugunterricht in Mönchengladbach</h1>

              <h2>Mit Spaß und spielend deine Ziele erreichen</h2>
              <StaticImage
                className="unterricht-img"
                src="../images/schlagzeugunterricht_in_mönchengladbach_moenchengladbach.jpg"
                alt="Schlagzeugunterricht in Mönchengladbach bei Carsten Grüter"
                fit="cover"
                placeholder="blurred"
                aspectRatio={21 / 9}
              />
              <h2 className='flex text-center'>Lerne Schlagzeug bei Carsten Grüter <br></br> individuell an deine Wünsche angepasst</h2>
              <p>
              Mein Schlagzeugunterricht holt dich dort ab, wo du stehst. Egal ob Anfänger oder fortgeschritten, alt oder jung.<br></br> Schlagzeug lernen ist sehr individuell. Deshalb schaue ich immer mit professionellem Blick auf deine Wünsche.<br></br>
              Die dafür nötigen musikalischen und technischen Fähigkeiten werden mit viel Spaß und spielerisch erlernt. </p>
              <ul className="bullet">
                <li>Geeignet für jedes Alter und Level</li>
                <li>individuell an deine Wünsche angepasst</li>
                <li>keine Vorkenntnisse benötigt</li>
                <li>eigener Jitsi Server für Online Unterricht</li>
                <li>eigene Cloud für Unterrichtsmaterial</li>
                <li>Theorie und Praxis auch für die Studienvorbereitung</li>
                <li>Miet-Schlagzeug (Kauf) möglich</li>
              </ul>
              <StyledButton
                label="Probiere es aus bei einer kostenlosen Probestunde!"
                to="/kontakt"
              />

              {/*  <h3>Musik Produktion</h3>
              <ul className="bullet">
                <li>Beats produzieren</li>
                <li>der eigene Klingelton</li>
                <li>die Songidee</li>

                <li>erste Schritte mit Cubase, Logic, Live etc.</li>
                <li>keine Vorkenntnisse benötigt</li>
              </ul>
              <p>TIEFER EINTAUCHEN? </p>
              <p>
                Was ist ein Compressor, wie benutze ich einen EQ, und warum
                eigentlich?<br></br> Vom Recording über Mixing, Mastering zur
                CD/MP3
              </p> */}
              <p>
                Ab sofort in der <br></br>
                <a
                  href="https://redbox-mg.de/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {' '}
                  RED BOX
                </a>{' '}
                am SparkassenPark
                <br />
                Am Nordpark 299 <br />
                41069 Mönchengladbach
              </p>
            </div>
            <div className="center unterricht">
              <h3 id="ankeronline">Online Unterricht</h3>
              <StaticImage
                className="unterricht-img"
                src="../images/online_unterricht.jpg"
                alt="Online Unterricht Bild"
                fit="cover"
                placeholder="blurred"
                aspectRatio={21 / 9}
              />
              <p>
                Auch online ist der Unterricht möglich! DSGVO konform und sicher
                steht hier für ein eigener Jitsi Server zur Verfügung.{' '}
              </p>
              <a
                href="https://online.drum-up.de"
                target="_blank"
                rel="noreferrer noopener"
                className="btn-sideNav"
              >
                {' '}
                zum Online Unterricht
              </a>{' '}
            </div>
            <div className="center unterricht">
              <h3 id="ankercloud">Drum UP Cloud</h3>
              <StaticImage
                className="unterricht-img"
                src="../images/drumupcloud.png"
                alt="Drum Up Cloud Bild"
                fit="cover"
                placeholder="blurred"
                aspectRatio={21 / 9}
              />
              <p>
                Für Unterrichtsmaterial steht eine eigene Cloud Lösung auf
                Nextcloud Basis bereit. Hier gibt es Starter Videos, Noten und
                für jeden Schüler einen perönlichen Bereich für sein
                individuelles Programm und Material aus den Unterrichtsstunden.
              </p>
              <a
                href="https://trommelcloud.drum-up.de"
                target="_blank"
                rel="noreferrer"
                className="btn-sideNav"
              >
                {' '}
                zur Drum UP Cloud
              </a>{' '}
            </div>
            <div className="center unterricht preise">
              <h3 id="ankerpreise">Preise Unterricht</h3>

              <table>
                <tbody>
                  <tr>
                    <td>30 Min</td>
                    <td>Preis pro Monat</td>
                    
                  </tr>
                  <tr>
                    <td>Probestunde</td>
                    <td>0 €</td>
                   
                  </tr>
                  <tr>
                    <td>Schüler/Studenten</td>
                    <td>79 €</td>
                   
                  </tr>
                  <tr>
                    <td>Erwachsene</td>
                    <td>89 €</td>
                   
                  </tr>
                  
                </tbody>
              </table>
              <h4>Gutschein ohne Vertragsbindung</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Gutschein S 4x30 Minuten</td>

                    <td>99 €</td>
                  </tr>
                  <tr>
                    <td>Gutschein L 4x60 Minuten</td>

                    <td>190 €</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
            <div className="center mt2 ">
              <h3 className="mb2"> Auszug aus den AGBs</h3>
              <ul>
                <li>
                  <h4>1. Ferien</h4>
                  <p>
                    An gesetzlichen Feiertagen und in den Schulferien des
                    Bundeslandes NRW für allgemeinbildende Schulen fällt der
                    Unterricht aus, ohne dass dies Einfluss auf das vereinbarte
                    Honorar hat.
                  </p>
                </li>
                <li>
                  <h4>2. Unterrichtsausfall / Krankheit</h4>
                  <p>
                    Terminabsprachen sind grundsätzlich mit der Lehrkraft
                    persönlich zu regeln. Eine Absage der Unterrichtsstunde
                    durch die Schülerin / den Schüler soll 48 Stunden vor dem
                    Termin bei der Lehrkraft erfolgen. Durch die Schuld des
                    Schülers versäumte Stunden werden nicht nachgegeben oder
                    erstattet. Die Schülerin / der Schüler verpflichtet sich,
                    nicht zum Unterricht zu erscheinen, wenn sie/ er so krank
                    ist, dass für die Lehrkraft eine unmittelbare
                    Ansteckungsgefahr besteht. Das Unterrichtshonorar bleibt
                    hiervon unberührt. Bei längerer Erkrankung der Schülerin/
                    des Schülers oder der Lehrkraft entfällt das anteilige
                    Honorar nach Ablauf von sechs Wochen. Durch die Schuld der
                    Lehrkraft versäumte Stunden werden nach- bzw. vorgegeben,
                    die Lehrkraft bietet hierzu bis zu drei Ausweichtermine zur
                    Auswahl an. Sollte der Lehrkraft das Nach- bzw. Vorgeben
                    nicht möglich sein, werden die Stunden finanziell erstattet.
                  </p>
                </li>

                <li>
                  <h4>3. Kündigung</h4>
                  <p>
                    Die Kündigungsfrist beträgt 4 Wochen.<br></br>
                    In begründeten Ausnahmefällen, z.B. Wohnortswechsel, kann
                    der Vertrag einvernehmlich vorzeitig aufgelöst werden. Ein
                    Anspruch auf vorzeitige Auflösung besteht nicht.
                  </p>
                </li>
                <li>
                  <h4>4. Probezeit</h4>
                  <p>
                    Die Probezeit beträgt einen Monat. Während der Probezeit ist
                    eine Kündigung mit Wochenfrist möglich.
                  </p>
                </li>
                <li>
                  <p>
                    Die kompletten AGB sind vor Ort einzusehen und liegen jedem
                    Vertrag bei.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Unterricht;
