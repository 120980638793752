import React, { useRef, useEffect } from 'react';

import gsap from 'gsap';

import Seo from '../components/Seo';
import '../style/Home.scss';
import CarouselComp from '../components/CarouselComp';
import Card from '../components/Card';

const Home = () => {
  const h1Ref = useRef();
  const carouselRef = useRef();
  const cardRef = useRef();
  const textRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      h1Ref.current,
      { opacity: 0, scale: 0 },
      { opacity: 1, scale: 1, duration: 1 }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      carouselRef.current,
      { opacity: 0, x: -100 },
      { opacity: 1, x: 0, duration: 2, ease: 'back.out(2)', delay: 1 }
    );
  }, []);
  useEffect(() => {
    gsap.fromTo(
      cardRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, ease: 'back.out(2)', delay: 2 }
    );
  }, []);

  useEffect(() => {
    gsap.fromTo(
      textRef.current,
      { opacity: 0 },
      { opacity: 1, duration: 1, delay: 3 }
    );
  }, []);
  return (
    <div className="home center">
      <Seo
        title="Startseite"
        keywords="drum up, Schlagzeugunterricht, Carsten Grüter, Teamtraining"
        description="drum up Carsten Grüter - Schlagzeugunterricht, Teamtraining und Musikproduktion aus Mönchengladbach  "
      />

      <div ref={h1Ref}>
        <h1 className=" center">
          <span className="h1Plus du">
            drum up<br></br>{' '}
          </span>
          <span className="h1Plus">
            Entdecke die <span className="underline">Musik</span> in dir!
          </span>
        </h1>
      </div>

      <div className="" ref={carouselRef}>
        <CarouselComp />
      </div>
      <div className="cards" ref={cardRef}>
        <Card
          h3="Unterricht"
          to="/unterricht"
          linktitle="--> Unterricht"
          text=" Über 20 Jahre Erfahrung mit Musik, Menschen und Unterricht helfen
            mir, für jeden ein individuelles Konzept zu entwickeln.
          "
        />
        <Card
          h3="Teamtraining"
          to="/teamtraining"
          linktitle="--> Teamtraining"
          text=" drum up Teamtraining nutzt Energie und Prozesse einer (Trommel-)
            Band, um mit Mitarbeitern, Kunden, Gästen, Kindern, Schülern oder
            Vereinsmitgliedern ein kraftvolles Erlebnis zu erschaffen.
          "
        />
        <Card
          h3="Recording"
          to="/recording"
          linktitle="--> Recording"
          text="Aufnahmen von dir? Deiner Band? Als Geschenk? Bei mir
            klingst du immer gut!
          "
        />
      </div>
      <div className="card center padding" ref={textRef}>
        <div>
          <h4>drum up - Der Duden sagt:</h4>
          <p>to drum sb. up - jdn. zusammentrommeln</p>
          <p>to drum up interest for sth. - Interesse für etw. wecken </p>
          <p> to drum up enthusiasm for sth. - Begeisterung für etw. wecken</p>
          <p>to drum up conversation - ein Gespräch ankurbeln [ugs.] </p>
          <p> to drum up business - die Werbetrommel rühren</p>
          <h4>Ich sage:</h4>
          <p>to drum up - ZUSAMMEN TROMMELN - entdecke die Musik in dir!</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
